export default class GeoJSONFilter {
  app = {};

  constructor(app) {
    this.app = app;
  }

  filterSourceData = (sourceData, filters) => {
    const doFilter = (sourceData, filters) => {
      const filteredData = { ...sourceData };
      filteredData.features = [];
      filteredData.features = sourceData.features.filter((dataPoint) => {
        let found = false;

        //needed for Datefilter - it's a mess
        let projectTimeline = { start: 0, end: 0 };
        let filterTimeline = { lower: 0, upper: 0 };

        filters.forEach((filter) => {
          const filterValue = filter.value;
          const filterName = filter.property;
          let dataPointValues = dataPoint.properties[filter.property];

          if (!Array.isArray(dataPointValues)) dataPointValues = [dataPointValues]

          dataPointValues.forEach((dataPointValue) => {
            if (
              filterName.includes("project_start") ||
              filterName.includes("project_end")
            ) {
              const currentYear = new Date().getFullYear();

              projectTimeline.start =
                dataPoint.properties.field_project_start === ""
                  ? currentYear
                  : dataPoint.properties.field_project_start;
              projectTimeline.end =
                dataPoint.properties.field_project_end === ""
                  ? currentYear
                  : dataPoint.properties.field_project_end;

              dataPointValue =
                dataPoint.properties[filter.property] === "" ||
                dataPoint.properties[filter.property] > currentYear
                  ? currentYear
                  : dataPoint.properties[filter.property];

              if (filterName.includes("project_start")) {
                filterTimeline.lower = filterValue;
              }

              if (filterName.includes("project_end")) {
                filterTimeline.upper = filterValue;
              }

              if (
                projectTimeline.start <= filterTimeline.lower &&
                projectTimeline.end >= filterTimeline.lower
              ) {
                found = true;
              }
              if (
                projectTimeline.start >= filterTimeline.lower &&
                projectTimeline.start <= filterTimeline.upper
              ) {
                found = true;
              }
            }

            if (dataPointValue === filterValue) {
              found = true;
            }
          })

        });

        return found;
      });
      return filteredData;
    };

    filters = this.sortFiltersByParentType(filters);

    /* const filterExample = {
      Type: [{ property: "value", value: "value" }],
      Status: [{ property: "value", value: "value" }],
    };*/

    for (const key in filters) {
      const filter = {};
      filter[key] = filters[key];
      sourceData = doFilter(sourceData, Object.values(filter)[0]);
    }

    sourceData.numberOfDataPoints = sourceData.features.length;

    return sourceData;
  };

  sortFiltersByParentType = (filters) => {
    let sortedFilters = {};
    filters.forEach((filter) => {
      if (!filter.checked) {
        return;
      }

      if (!sortedFilters[filter.parentType]) {
        sortedFilters[filter.parentType] = [filter];
      } else {
        sortedFilters[filter.parentType].push(filter);
      }
    });

    if (Object.entries(sortedFilters).length === 0) {
      sortedFilters = { Type: [this.getEmptyFilter()] };
    }

    return sortedFilters;
  };

  getEmptyFilter = (filterType) => {
    const emptyFilter = {
      filterID: "None",
      filterType: filterType,
      checked: true, //filter will only be applied, if it is checked
      parentType: "None",
      property: "None",
      value: "None",
    };

    return emptyFilter;
  };

  initializeFilter = (
    typeID,
    data,
    relevantFeatureNames = [
      { internalName: "type1", name: "Type" },
      { internalName: "project_status", name: "Status" },
      { internalName: "type2", name: "Area" },
    ]
  ) => {
    let filterObject = [];

    const foundFeatures = [];

    data.forEach((entry) => {
      relevantFeatureNames.forEach((featureName) => {
        let features = entry.properties[featureName.internalName];
        if (!Array.isArray(features)) features = [features]

        features.forEach((feature) => {
          if (!foundFeatures.includes(feature) && feature.length) {
            let checked = true;
            if (featureName.name === "Area") {
              checked = false;
            }

            const name = feature.toLowerCase().replace(" ", "-");
            const filterID = typeID + "-" + featureName.internalName + "-" + name;

            foundFeatures.push(feature);
            filterObject.push({
              property: featureName.internalName,
              value: feature,
              checked: checked,
              parentType: featureName.name,
              operator: "==",
              filterType: typeID,
              filterID: filterID,
            });
          }
        })
      });
    });

    filterObject = this.addDateFilters(typeID, filterObject);

    return filterObject;
  };

  addDateFilters = (typeID, filterObject) => {
    const startYear = this.app.state.dateFilter.all[0];
    const currentYear = this.app.state.dateFilter.all[1];
    let checked = false;

    for (let year = 0; year <= currentYear; year++) {
      if (year === 1) {
        year = 2000;
      }

      if (year === startYear) {
        checked = true;
      }

      let filter = {
        property: "field_project_start",
        value: year,
        checked: checked,
        parentType: "Date",
        operator: ">=",
        filterType: typeID,
        filterID: `${typeID}-field_project_start-${year}`,
      };
      filterObject.push(filter);
    }

    for (let year = 2000; year <= currentYear; year++) {
      if (year === currentYear) {
        year = 3000;
      }
      let filter = {
        property: "field_project_end",
        value: year,
        checked: true,
        parentType: "Date",
        operator: "<=",
        filterType: typeID,
        filterID: `${typeID}-field_project_end-${year}`,
      };
      filterObject.push(filter);
    }

    return filterObject;
  };
}
